
import styles from './Header.module.scss';
import classnames from "classnames"
import { observer } from "mobx-react-lite"
import { mainStore } from '../store/Main.store';
import { PAGE_TYPE } from '../Consts';


const tabSettings = [
    { title: "主页", page: PAGE_TYPE.Home },
    { title: "关于我们", page: PAGE_TYPE.About },
    { title: "艺术疗愈", page: PAGE_TYPE.Art },
    { title: "培训认证", page: PAGE_TYPE.Train },
    { title: "学术资源", page: PAGE_TYPE.Resource },
    { title: "会员权益", page: PAGE_TYPE.Member }]

function Header() {
    let isPC = mainStore.windowSize.width > mainStore.windowSize.height;

    let select_color = "#6a69694d"
    let hover_color = "#6a69697d"
    let default_color = "#00000000"
    function over(event: any) {
        event.target.style.backgroundColor = hover_color
    }

    function out(event: any) {
        event.target.style.backgroundColor = mainStore.curTab == +event.target.id ? select_color : default_color
    }

    function getStyle(page: PAGE_TYPE) {
        return { backgroundColor: mainStore.curTab == page ? select_color : default_color }
    }

    return (
        <div className={styles.root}>
            <div className={styles.title_bg}></div>
            <div className={styles.logo_container}  onClick={()=>{mainStore.setCurTab(PAGE_TYPE.Home)}}>
                <div className={styles.logo} />
                {isPC ?
                    <div>
                        <div className={styles.logo_text} >國際創意藝術治療學會</div>
                        <div className={styles.logo_text_en}>INTERNATIONAL ASSOCIATION OF CREATIVE ART THERAPY</div>
                    </div> :
                    null}
            </div>
            <div className={styles.container}>
                {
                    tabSettings.map((tab, index) => {
                        return <div
                            onClick={() => { mainStore.setCurTab(tab.page) }}
                            key={index}
                            id={"" + tab.page}
                            onMouseOver={over}
                            onMouseOut={out}
                            className={classnames(styles.btn, styles.btn_focus)}
                            style={getStyle(tab.page)}>{tab.title}</div>
                    })
                }
            </div>
        </div>
    )
}

export default observer(Header);