
import styles from './Bottom.module.scss';
import classnames from "classnames"
import { observer } from "mobx-react-lite"
import { mainStore } from '../store/Main.store';

const links = [
    { name: "美国心理学会（APA）", url: "https://www.apa.org/", img: require("../imgs/links/3.png") },
    { name: "美国艺术治疗学会", url: "https://arttherapy.org/", img: require("../imgs/links/4.png") },
    { name: "美国舞动治疗学会", url: "https://www.adta.org/", img: require("../imgs/links/12.png") },
    { name: "中国心理卫生协会", url: "https://www.camh.org.cn/", img: require("../imgs/links/10.png") },
    { name: "中国科学院心理研究所", url: "http://www.psych.ac.cn/", img: require("../imgs/links/8.png") },
    { name: "中国人口文化促进会（表达性艺术分会）", url: "https://www.cnpca.cn/", img: require("../imgs/links/9.png") },
    { name: "台湾艺术治疗学会", url: "https://www.arttherapy.org.tw", img: require("../imgs/links/5.png") },
    { name: "香港园艺治疗协会", url: "http://www.hkath.org", img: require("../imgs/links/7.png") },
    { name: "峻峰教育中心", url: "https://www.junfeng.center/", img: require("../imgs/links/1.png") },
    { name: "原型意象象征档案库", url: "https://aras.org/", img: require("../imgs/links/11.png") }
]

function Bottom() {
    let isPC = mainStore.windowSize.width > mainStore.windowSize.height;

    return (
        <div className={styles.root}>
            <div className={styles.links}>
                {
                    links.map((data, index) => {
                        return <div
                            onClick={() => { /*window.open(data.url, "_blank") */}}
                            key={index}
                            className={classnames(styles.img, styles.btn_focus)}
                            style={{ backgroundImage: "url(" + data.img + ")" }}></div>
                    })
                }
            </div>
        </div>
    )
}

export default observer(Bottom);