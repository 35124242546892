import classnames from "classnames";
import { Utils } from "../../store/Utils";
import styles from "./About.module.scss"
import { mainStore } from "../../store/Main.store";
import Bottom from "../../bottom/Bottom";

function About() {
    let isPC = mainStore.windowSize.width > mainStore.windowSize.height;
    return <div className={styles.root}>
        <div className={styles.container}>

            <div className={classnames(styles.subContainer, isPC ? null : styles.mobile)}>
                {
                    Utils.getContentView(`t2-学会介绍 

国际创意艺术疗愈学会是一个依法成立的致力于凝聚国内外在艺术疗愈领域从事研究和应用专家、学者和各界专业人士的公益性、非营利性社会组织。
            `, {
                        "t1": classnames(styles.t1, isPC ? styles.pc_right : styles.mobile),
                        "t2": classnames(styles.t2, isPC ? styles.pc_right : styles.mobile),
                        "txt": classnames(styles.txt, isPC ? styles.pc_right : styles.mobile)
                    })
                }
                <div className={classnames(styles.img, isPC ? null : styles.mobile)} style={{ backgroundImage: "url(" + require("../../imgs/about/1.jpg") + ")" }}></div>
            </div>


            <div className={classnames(styles.subContainer, isPC ? null : styles.mobile)}>

                {
                    Utils.getContentView(`t2-学会宗旨 

我们相信创意艺术能够激发创造力及发挥个人特质，促进人格发展及心身合一。每个人都可藉着艺术自我探索及认识自己，达至自我疗愈及整合，促进个人成长及团体发展。

我们旨在通过跨学科的努力，结合艺术、心理、医学、哲学、文学与中国传统文化六个领域，推动创意艺术疗愈成为规范化的心理健康专业，透过多元艺术，包括绘画、音乐、舞动、戏剧、梦、园艺及诗歌等，助人表达情感及提高自我意识，并建立一个在整个职业生涯中支持艺术疗愈师的团体。
        `, {
                        "t1": classnames(styles.t1, isPC ? styles.pc_right : styles.mobile),
                        "t2": classnames(styles.t2, isPC ? styles.pc_right : styles.mobile),
                        "txt": classnames(styles.txt, isPC ? styles.pc_right : styles.mobile)
                    })
                }
                <div className={classnames(styles.img, isPC ? styles.pc_left : styles.mobile)} style={{ backgroundImage: "url(" + require("../../imgs/about/2.jpg") + ")" }}></div>
            </div>



            <div className={classnames(styles.subContainer, isPC ? null : styles.mobile)}>
                {
                    Utils.getContentView(`t2-学会任务 

建立并维持艺术疗愈专业伦理及临床服务基准。
提高艺术疗愈教育水平并辅导专业人员之进修。
增进国内外艺术疗愈专业人士之间联系与合作。
增进与其他专业团体及国际组织之联系与合作。
推动有关艺术疗愈学术研究，举办学术研讨会。
介绍、编纂及出版艺术治疗方面之刊物及书籍。
其他有助于达成本会宗旨之事项。


`, {
                        "t1": classnames(styles.t1, isPC ? styles.pc_right : styles.mobile),
                        "t2": classnames(styles.t2, isPC ? styles.pc_right : styles.mobile),
                        "txt": classnames(styles.txt, isPC ? styles.pc_right : styles.mobile)
                    })
                }
                <div className={classnames(styles.img, isPC ? null : styles.mobile)} style={{ backgroundImage: "url(" + require("../../imgs/about/3.jpg") + ")" }}></div>
            </div>
        </div>
        <Bottom></Bottom>
    </div>
}


export default About;