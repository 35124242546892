import classnames from 'classnames';
import styles from './Home.module.scss';
import { mainStore } from '../../store/Main.store';
import { Utils } from '../../store/Utils';
import { observer } from 'mobx-react-lite';
import Bottom from '../../bottom/Bottom';


function Home() {
    let isPC = mainStore.windowSize.width > mainStore.windowSize.height;

    return <div className={styles.root}>
        <div className={styles.main_bg} >
            <div className={classnames(styles.slogan, !isPC && styles.mobile)}>艺术疗愈是一种智慧，<br />一种自然的力量。</div>
        </div>

        <div className={styles.container}>
            {
                Utils.getContentView(`t1-IACAT简介

国际创意艺术治疗学会（International Associations of Creative Art Therapy)是一个依法成立的致力于凝聚国内外在艺术疗愈领域从事研究和应用专家、学者和各界专业人士的公益性、非营利性社会组织。
        
        
t1-Creative Art Therapy创意艺术疗愈

创意艺术疗法是一种结合创造性艺术表达和心理疗愈的助人专业，致力于透过多元艺术，包括绘画、视觉艺术、音乐、舞动、戏剧及创意写作等，助人表达情感及提高自我意识，改善认知和感觉运动功能，培养自尊和自信，提高情绪调节能力，促进洞察力，增强人际关系，减少和解决冲突和困扰，提升身、心、灵健康。            
            
            `, { "t1": styles.t1, "t2": styles.t2, "txt": styles.txt })
            }
        </div>
        <Bottom></Bottom>
    </div>
}


export default observer(Home);