export class Utils {
    public static getContentView(content: string, styles: { "t1"?: any, "t2"?: any, "txt"?: any, t3?: any }) {
        var str_split = content.split("\n")
        return <div>
            {
                str_split.map((line: string, index: number) => {
                    while(line.length<4){
                        line += " "
                    }
                    let first3 = line.match(/\S\S\S/)
                    let prefix = first3 == null ? "" : first3[0]
                    switch (prefix) {
                        case "t1-":
                            return <div key={index} className={styles["t1"]}>{line.replace(prefix, "")}</div>
                        case "t2-":
                            return <div key={index} className={styles["t2"]}>{line.replace(prefix, "")}</div>
                        case "t3-":
                            return <div key={index} className={styles["t3"]}>{line.replace(prefix, "")}</div>
                        default:
                            return <div key={index} className={styles["txt"]}>{line}</div>
                    }
                })
            }
        </div>
    }
}