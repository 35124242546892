import { Utils } from "../../store/Utils";
import styles from "./Resource.module.scss"
import Slider from 'react-slick';
//在头部引入css
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { observer } from "mobx-react-lite";
import { mainStore } from "../../store/Main.store";
import Bottom from "../../bottom/Bottom";


const resources_imgs = [require("../../imgs/resource/1.jpg"),
require("../../imgs/resource/2.jpg"),
require("../../imgs/resource/3.jpg"),
require("../../imgs/resource/4.jpg"),
require("../../imgs/resource/5.jpg"),
require("../../imgs/resource/6.jpg"),
require("../../imgs/resource/7.jpg"),
require("../../imgs/resource/8.jpg"),
require("../../imgs/resource/9.jpg"),]

function Resource() {
    let lineCount = mainStore.windowSize.width > mainStore.windowSize.height ? 7 : 2;
    return <div className={styles.root}>
        <div className={styles.container}>
            <Slider dots={false}
                infinite={true}
                speed={500}
                autoplaySpeed={2000}
                slidesToShow={lineCount}
                slidesToScroll={1}
                className={styles.carousel}
                autoplay={true}>
                {
                    resources_imgs.map((img, index) => {
                        return <div key={index}><div className={styles.book} key={index} style={{ backgroundImage: "url(" + img + ")" }}></div></div>
                    })}
            </Slider>
            {
                Utils.getContentView(`
t2-中文著作：

琳达晓乔（2018）。舞动：以肢体创意开启心理疗愈之旅。中国人民大学出版社
梁文龍、謝頴思（2018）。艺术是一种治疗剂。澳門人出版有限公司、澳門心理研究學會
李孟潮（2022）。 荣格的30个梦（大陆版本：《自愈与成长》）。心灵工坊
王继堃（2019）.无论走得多远，家庭总是如影随形-家庭治疗16讲。华东师范大学出版社
陈灿锐，高艳红（2016）。儿童曼陀罗绘画分析 理论与实践。暨南大学出版社
陈灿锐，高艳红（2019）。化曼陀罗心灵疗愈绘本。暨南大学出版社
陈灿锐，高艳红（2014）。心灵之路：曼陀罗成长自愈绘本。暨南大学出版社
陈灿锐，高艳红（2014）。心灵之镜：曼陀罗绘画治疗。暨南大学出版社
李郁文（2001），团体动力学——群体动力的理论。台北:桂冠图书股份有限公司
祝卓宏、锁朋（2009）。灾后心理热线志愿者培训手册。科学出版社
童欣（2017）。绘画心理分析：追寻画外之音。机械工业出版社
作者:樊富珉，何瑾（2022）。团体心理辅导（第二版）。华东师范大学出版社
江学滢（2022）。艺术治疗图卡的100种应用（修订版）。台北：商周。
林端容(2021)。高龄者团体艺术治疗：失智症的介入与预防活动手册（第二版)。台北：五南。
林端容(2021)。高龄艺术与预防失智症 : 艺术课程活动设计。台北：五南。
江学滢、王华雯、江芊玥、朱惠琼、金传珩、林晓苹、范维昕、黄暄文、黄凯嫈、杨舜如、廖学加、蔡汶芳 (2021)。12种场域的艺术治疗实务与观点分享。台北: 商周。
蔡汶芳（2021）。玩艺术，一起老：老人艺术治疗的理论、实务与案例分享。台北：商周。
陆雅青（2016）。艺术治疗（第四版）。台北：心理。
吴明富、徐玟玲（2016）。艺术治疗工作坊：媒材应用与创作指引。台北：洪叶文化。
林端容 (2016) 。忧郁症者的疗愈与蜕变：案主中心艺术治疗。台中：迦密文化。
王秀绒（2016）。艺术治疗理论与实务。台北：洪叶文化。
吴明富、黄传永（2013）。艺树园丁：悲伤与失落艺术治疗。台北：张老师文化。
林端容（2012）。艺术治疗：心灵之旅。台中：陞运。
范睿榛（2012）。艺术治疗：家庭动力绘画概论（第三版）。台北：五南。
何长珠等（2012）。表达性艺术治疗十四讲（第二版）。台北：五南。
吴明富（2010）。走进希望之门：从艺术治疗到艺术育疗。台北：张老师文化。
梁翠梅（2009）。艺术治疗：身心灵合一之道。台北：华都文化。
范睿榛（2006）。艺术治疗导论。台北：新学林。
吕素贞（2005）。超越语言的力量：艺术治疗在安宁病房的故事。台北：张老师文化。
陆雅青（2000）。艺术治疗团体实务研究：以破碎家庭儿童为例。台北：五南。
其他相关书籍（依年份排序）
江学滢（2016）。玩艺术，酷思考。台北：商周。
Cameron, J.(2010)。创作，是心灵疗愈的旅程（锺清瑜 译）。台北：橡树林。The ARTIST’S WAY: The Spiritual Path to Higher Creativity.（原著作出版于2002）
吕旭亚 等(2009)。心灵秘境：11个生命蜕变的故事。台北：心灵工坊。
刘俊余、陈素秋（2009）。我的笔衣罐：一个肯纳青年的绘画课。台北：心灵工坊。
Capacchione, L.（2008）。玩多元艺术解放压力（沈文玉 译）。台北：生命潜能。The Art of Emotional Healing.（原著作出版于2006）
侯俊明（2007）。镜之戒：一个艺术家376天的曼陀罗日记。台北：心灵工坊。
盛正德（2002）。以画疗伤：一位艺术家的忧郁之旅。台北：心灵工坊。

t2-英文著作：

JOEL KROEKER（乔尔·克罗克），Jungian Music Psychotherapy When Psyche Sings（2019），Routledge
蕾妮·伊姆娜（Renée Emunah）（2018） 演出真实的生命：戏剧治疗的过程、技术及展演。（徐琳 别士敏 译）。北京师范大学出版社
(美)莫斯奇里(2012)。绘画心理治疗：对困难来访者的艺术治疗（陈侃 译）。中国轻工业出版社
[美]露西娅·卡帕基奥(2020)。释放内在的小孩：情绪的艺术疗愈（黄珏苹 译）。中国人民大学出版社
Huxtable, A. E., Wolf Bordonaro, G., & Schmanke, L. (2022)。图解艺术治疗（江学滢 译）。台北：商周。A Graphic Guide to Art Therapy（原著出版年2021）
Swan-foster, N. (2022)。荣格取向艺术治疗：梦、意象和分析心理学指南（丁凡 译）。台北：商周。Jungian Art Therapy: Dreams, Images, and Analytical Psychology（原著出版年2017）
McNiff, S.（2021）。艺术本位治疗研究法（三版）（吴明富 译）。Art-Based Research（原著出版年1998）
Martin, N. (2020)。自闭症儿童早期艺术育疗（吴明富、黄千千 译）。台北：洪叶文化。Art as an early intervention tool for children with autism（原著出版年2009）
Rubin, J. A. (2019)。艺术治疗大全：理论与技巧（三版）（陆雅青、陈美伊、蔡汶芳、吕煦宗、许玫倩、谢湘蓁、周怡君、张梅地、吴亭君 译）。Approaches to Art Therapy: Theory and Technique /3e（原著出版年2016）
Rappaport, L. (2018)。正念与各类型艺术治疗：理论与实务（吴明富、陈雪均、江佳芸 译）。台北：心理。Mindfulness and the Arts Therapies: Theory and Practice（原著出版年2013）
Hinz, L. D.(2018)。表达性治疗连续系统—运用艺术于治疗中的理论架构（金传珩 译）。台北：洪叶文化。(原著出版于2009)
Moon, C. H. (2017)。工作室艺术治疗：艺术本位治疗取向（周大为、吴明富 译）。台北：洪叶文化。Studio Art Therapy: Cultivating the Artist Identity in the Art Therapist（原著出版年2001）
Moon, B. (2014)。艺术本位团体治疗：理论与实务 (   吴明富等 译)。台北：洪叶文化。Art-Based Group Therapy: Theory and Practice.（原著作出版年2010）
Liebmann, M. (2013) 。艺术治疗与团体工作：实例与活动（赖念华 译）。台北：张老师文化。Art Therapy for Groups: A Handbook of Themes and Exercises.（原著作出版年1986）
Allen, P. B.（2013）。疗愈，从创作开始：艺术治疗的内在旅程（江孟蓉 译）。台北：张老师文化。Art is a Way of Knowing: A Guide to Self-Knowledge and Spiritual Fulfillment through Creativity.（原著作出版年1995）
Malchiodi, C. A.（2012）。创造性治疗：创伤儿童的实务工作手册（许智杰、谢政廷 译）。台北：学富文化。Creative Interventions: with Traumatized Children.（原著作出版年2008）
Malchiodi, C. A.（2011）。艺术治疗：自我工作手册（朱惠琼 译）。台北：心理。The Art Therapy Sourcebook.（原著作出版年2006）
Moon, B.（2011）。以画为镜：存在艺术治疗（丁凡 译）。台北：张老师。Existential Art Therapy: The Canvas Mirror(3rd Ed.).（原著作出版年2009）
Kerr, C., Hoshino, J., Sutherland, J., Parashak, S. T., McCarley, L. L.（2010）。家族艺术治疗：理论与实践的基础（吴婷盈 译）。台北：五南。Family Art Therapy: Foundations of Theory and Practice.（原著作出版年2007）
Knill, P. J., Levine, E. G., Levine, S. K.（2010）。表达性艺术治疗：理论与实务（许玉芳 译）。台北：五南。Principle and Practice of  Expressive Arts Therapy: Toward A Therapeutic Aesthetics.（原著作出版年2004）
Matthews, J.（2010）。线画与绘画：儿童与视觉再现（赖昭文 译）。台北：心理。Drawing and Painting: Children and Visual Representation.（原著作出版年2003）
Malchiodi, C.（2007）。艺术治疗：心理专业者实务手册（陆雅青等 译）。台北：学富文化。Handbook of Art Therapy.（原著作出版年2002）
Ogdon, D.（2008）。心理诊断与人格测验手册（陆雅青、刘同雪 译）。台北：心理。Psychodiagnostics and Personality Assessment: A Handbook.（原著作出版年2001）
Malchiodi, C.（2008）。儿童绘画治疗：儿童的心灵之窗（吴武烈、范琼芳 译）。台北：五南。Understanding Children’s Drawings.（原著作出版年1998）
Levine, S. K., Levine, E. G.（2007）。表达性艺术治疗概论（苏湘婷、陈雅丽、林开诚 译）。台北：心理。 Principle and Practice of  Expressive Arts Therapy: Toward A Therapeutic Aesthetics.（原著作出版年2004）
Heath, S.（2007）。艺术的生活态度：艺术治疗活动与自我发现（黄丽蓉 译）。台北：五南。Art@tudes?: Mastering the Art of Your Attitude.（原著作出版年2002）
Moon, B.（2006）。青少年艺术治疗（许家绫 译）。台北：心理。The Dynamics of Art Therapy with Adolescents.（原著作出版年1998）
Kramer, E.（2004）。儿童艺术治疗（江学滢 译）。台北：心理。Art as Therapy with Children.（原著作出版年1994）
Spring, D.（2004）。影像与幻象：解离性身分疾患（DID）之艺术治疗手记（戴百宏、施婉清 译）。台北：心理。Image and Mirage: Art Therapy with Dissociative Clients.（原著作出版年2001）
Oster, G., Gould, P.（2002）。绘画评估与治疗：心理卫生专业人员指南（吕俊宏、刘静女 译）。台北：心理。Using Drawing in Assessment and Therapy: A Guide for Mental Health Professionals.（原著作出版年1987）
Rosal, M. L.（1997）。儿童艺术治疗（陆雅青 译）。台北：五南。Approaches to Art Therapy with Children.（原著作出版年1996）
McNiff, S.（1996）。艺术治疗（许锣湾 译）。台北：新路。Art as Medicine: Creating a Therapy of the Imagination.（原著作出版年1992）
McNiff, S.（1996）。艺术治疗的理论与实务（陈鸣 译）。台北：远流。Images of Art Therapy: New Developments in Theory and Practice.（原著作出版年1987）

            
            
            
            `, { "t1": styles.t1, "t2": styles.t2, "txt": styles.txt })
            }
        </div>
        <Bottom></Bottom>
    </div>
}


export default observer(Resource);