import Bottom from "../../bottom/Bottom";
import { Utils } from "../../store/Utils";
import styles from "./Members.module.scss"

function Resource() {
    return <div className={styles.root}>
        <div className={styles.container}>
            <div className={styles.img1}></div>
            {
                Utils.getContentView(`t2-会员类别

本会会员分有专业会员、一般会员与团体会员。欲向本会申请会员的朋友们，请参考学会章程，填写会员申请表格，经审查委员与理监事会议审查，审查通过后并缴交『入会费』，即享有会员之权益。
    
t3-一般会员

赞同本会宗旨，年满二十岁，具有下列资格之一者，经理事会审查通过者，得为本会一般会员。
国内外教育部认可之艺术、心理、社工、医疗、教育等相关系所毕业，认同本会宗旨，并从事医疗、教育及心理卫生相关工作。
国内外大专院校毕业，从事医疗、教育及心理卫生相关工作具两年以上经历。
国内外教育部承认之大学艺术治疗相关研究所毕业，且目前从事医疗、教育或心理卫生相关工作，且继续接受专业督导者。
    
t3-专业认证会员
    
赞同本会宗旨，年满二十岁，具有下列资格之一者，由专业认证会员两名介绍，经理事会审查通过者得为本会专业认证会员，专业认证会员等同于本会认可之创意艺术疗愈师，并由学会颁发认可之艺术疗愈师（音乐方向、绘画方向、舞动方向等）证照。
    
一般会员参与并通过本会办理或认可之创意艺术疗愈专业训练课程，并修满各艺术方向所规定的学分，得以提出申请成为专业认证会员。
     
t3-团体会员

凡赞同本会宗旨之公私立医务、教育或心理卫生服务相关机构，由会员二人以上之介绍，经本会理事会审查通过，得为本会团体会员。 
    
     
t2-会员权益

凡新入会完成会费缴交手续者，可免费开通200份【艺术疗愈资源库】账号。
本会会员参与本会主办或协办之相关专业训练课程或活动时，享有会员价。
本会会员享有权限使用网络资源，精选文章及下载著作、论文、学术资料。
优先参与心理研讨会、地面实习及实践活动，优先考虑转介来访者给专业资质会员。
            
            
            
            `, { "t1": styles.t1, "t2": styles.t2, "t3": styles.t3, "txt": styles.txt })
            }
        </div>
        <Bottom></Bottom>
    </div>
}


export default Resource;