import { useEffect } from 'react';
import Header from './header/Header';
import { mainStore } from './store/Main.store';
import Styles from './App.module.scss';
import Home from './pages/home/Home';
import About from './pages/about/About';
import { PAGE_TYPE } from './Consts';
import { observer } from 'mobx-react-lite';
import Art from './pages/art/Art';
import Train from './pages/train/Train';
import Resource from './pages/resource/Resource';
import Members from './pages/members/Members';

function App() {

  useEffect(() => {
    if (process.env.appEnv === 'PC') {
      console.log('cur env: PC.')
    } else if (process.env.appEnv === 'Mobile') {
      console.log('cur env: Mobile.')
    } else {
      console.log('cur env: null')
    }

    function handleWindowResize() {
      mainStore.setWindowSize({ width: window.innerWidth, height: window.innerHeight })
    }

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };

  }, [])


  var getPage = (type: PAGE_TYPE) => {
    switch (type) {
      case PAGE_TYPE.About:
        return <About></About>
      case PAGE_TYPE.Art:
        return <Art></Art>
      case PAGE_TYPE.Train:
        return <Train></Train>
      case PAGE_TYPE.Resource:
        return <Resource></Resource>
      case PAGE_TYPE.Member:
        return <Members></Members>
      default:
        return <Home></Home>
    }
  }

  return (
    <div className={Styles.root} style={{
      marginLeft: `${(mainStore.windowSize.width - Math.min(mainStore.windowSize.width, mainStore.windowSize.height * 1.4))/mainStore.windowSize.height*50}vh`
    }}>
      <Header></Header>
      {
        getPage(mainStore.curTab)
      }
    </div>
  );
}

export default observer(App);
