import { makeAutoObservable } from "mobx";
import { PAGE_TYPE } from "../Consts";

class MainStore {
    constructor() {
        makeAutoObservable(this)
    }

    public windowSize: { width: number, height: number } = { width: window.innerWidth, height: window.innerHeight }

    public setWindowSize(size: { width: number, height: number }) {
        this.windowSize = size
    }

    public curTab: PAGE_TYPE = PAGE_TYPE.Home
    public setCurTab(tab: PAGE_TYPE) {
        this.curTab = tab
    }
}

export const mainStore = new MainStore()