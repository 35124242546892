import classnames from "classnames";
import { Utils } from "../../store/Utils";
import styles from "./Art.module.scss"
import { mainStore } from "../../store/Main.store";
import Bottom from "../../bottom/Bottom";

function Art() {
    let isPC = mainStore.windowSize.width > mainStore.windowSize.height;

    return <div className={styles.root}>
        <div className={styles.container}>

            <div className={classnames(styles.subContainer, isPC ? null : styles.mobile)}>
                {
                    Utils.getContentView(`t2-什么是创意艺术疗愈？

创意艺术疗法是一种结合创造性艺术表达和心理疗愈的助人专业。创意艺术疗法用于改善认知和感觉运动功能，培养自尊和自我意识，培养情绪调节能力，促进洞察力，增强人际关系，减少和解决冲突和困扰。
`, {
                        "t1": classnames(styles.t1, isPC ? styles.pc_right : styles.mobile),
                        "t2": classnames(styles.t2, isPC ? styles.pc_right : styles.mobile),
                        "txt": classnames(styles.txt, isPC ? styles.pc_right : styles.mobile)
                    })
                }
                <div className={classnames(styles.img, isPC ? null : styles.mobile)} style={{ backgroundImage: "url(" + require("../../imgs/art/1.jpg") + ")" }}></div>
            </div>


            <div className={classnames(styles.subContainer, isPC ? null : styles.mobile)} style={{ height: "fit-content" }}>

                {
                    Utils.getContentView(`t2-什么是创意艺术疗愈师？

创意艺术疗愈师，是指受过专业训练的艺术疗愈工作者，透过个别、家庭或团体治疗的方式实施艺术疗愈。
        
艺术疗愈工作者提供一个安全而完善的空间，透过艺术媒材，从事创造性艺术表达，藉此意象表达，反映与统整个人的发展、能力、人格、兴趣、意念、潜意识与内心的情感状态。在疗愈关系中的表达经验和作品呈现出来的反馈，具有发展(成长)、预防、诊断和疗愈功能。
        
个体情感、问题、潜能与潜意识在治疗关系中被发掘与体悟，进而得以在艺术表达过程中加以解决与处理，帮助个体达致自我了解、调和情绪、改善社会技能、提升行为管理和问题解决的能力，促进自我转变与成长、人格统整及潜能发展。

表达性艺术分析与疗愈的基本信念：心灵具有自我治愈的自然倾向和深度智慧。这种技术可运用于心理咨询、社会工作、辅导机构发展、社区艺术和教育团体，促进个人成长和社会转化，改善当代人们的生活质量。
  `, {
                        "t1": classnames(styles.t1, isPC ? styles.pc_right : styles.mobile),
                        "t2": classnames(styles.t2, isPC ? styles.pc_right : styles.mobile),
                        "txt": classnames(styles.txt, isPC ? styles.pc_right : styles.mobile)
                    })
                }
                <div className={classnames(styles.img, isPC ? styles.pc_left : styles.mobile)} style={{ backgroundImage: "url(" + require("../../imgs/art/2.jpg") + ")" }}></div>
            </div>



            <div className={classnames(styles.subContainer, isPC ? null : styles.mobile)} style={{ height: "fit-content" }}>
                {
                    Utils.getContentView(`t2-创意艺术疗愈的对象

由于创意艺术疗愈中较关心的是个人的内在经验以及创作的过程，而非最后产生的作品，因此接受艺术疗愈的人无需具备任何艺术创作的经验或背景。
        
创意艺术疗愈服务的对象包括各种文化背景和各不同年龄层的需求人士，应用的范围扩及心理、教育、社会和医疗等专业助人服务领域。
        
依年龄分，可分为幼儿、儿童、青少年、成人与老人。
        
依案主问题可大略区分为下列几类：
        
精神健康领域：与精神健康相关之病患。包括精神分裂症、躁郁症、忧郁症、妄想症、烟瘾、酒瘾、 药瘾、人格问题、适应障碍、强迫症、各类恐惧症及创伤后压力症候群等患者。

发展与学习困难领域：发展迟缓、智能障碍、自闭症、注意力缺陷过动症、情绪障碍、学习障碍、行为问题等。

社会性需求者：家暴受害者、性侵被害者、监狱服刑者、新移民、游民、丧亲、婚姻问题、高风险家庭等。

支持性医疗领域：医院中生理疾病的住院病患，例如癌症病人等。

一般人：希望激发潜能、培养健康人格、寻求自我成长。

`, {
                        "t1": classnames(styles.t1, isPC ? styles.pc_right : styles.mobile),
                        "t2": classnames(styles.t2, isPC ? styles.pc_right : styles.mobile),
                        "txt": classnames(styles.txt, isPC ? styles.pc_right : styles.mobile)
                    })
                }
                <div className={classnames(styles.img, isPC ? null : styles.mobile)} style={{ backgroundImage: "url(" + require("../../imgs/art/3.jpg") + ")" }}></div>
            </div>
        </div>
        <Bottom></Bottom>
    </div>
}


export default Art;