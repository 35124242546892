import classnames from "classnames";
import styles from "./Train.module.scss"
import { mainStore } from "../../store/Main.store";
import { Utils } from "../../store/Utils";
import Bottom from "../../bottom/Bottom";

const contents = [{ content: `t2-中西合璧舞动治疗技术系统直播15讲
 
t3-授课导师： 琳达晓乔 教授
北京师范大学艺术治疗研究中心首席专家
美国认证高级舞动治疗师
25年以上舞动治疗临床经验

t3-认证单位：
国际创意艺术治疗学会；惟一心理学院

t3-舞动治疗简介： 
美国舞蹈治疗学会给舞动治疗的定义：舞动治疗是以动作来促进个人的情感、认知、生理和社会进行交融的心理治疗方法。舞动治疗影响人的感觉、思维、生理功能和行为的变化。舞动治疗运用舞动治愈身心和升华精神，不断融合文化人类学、心理动力学神经运动科学、艺术的心理学。逐步发展为创造性的理论和实践。通过舞动可以越过防御机制释放被压抑的不良情绪，治愈心灵创，伤构建积极的身体记忆，增强社会认知、改善沟通。`,
 url: require("../../imgs/train/1.jpg") },
{ content: `t2-梦的心理分析与疗愈技术系统培训直播16讲

t3-授课导师： 杨登乐 心理分析博士
中美高级精神分析连续培训项目翻译；国际梦的孵化与梦的工作协会创办会员；国际分析心理学会（IAAP）分析师候选人；美国太平洋研究院梦的孵化学院（Pacific graduate institute, Dream tending academy ）dream tender导师资质

t3-认证单位：国际创意艺术治疗学会；惟一心理学院

t3-梦的心理分析技术简介：
Dream Analysis（美国心理学会APA定义 ）最早始于精神分析，是一种心理治疗技术，通过对梦境进行分析，呈现潜意识层面的内容，来揭示潜在的心理动力或象征层面的意义。梦的心理分析与疗愈技术不仅仅可运用于心理治疗，通过记录梦、分析梦还可以达致自我疗愈，是一种有效的心灵自助工具。应用广泛可在学校、社区、医院、企业、儿童青少年、成人、个人与团体中应用，受众面广，形式深受欢迎。`, url: require("../../imgs/train/2.jpg") },
{ content: `t2-绘画心理实操技术系统直播50讲

t3-授课导师： 
梁文龙 博士，国际创意艺术治疗学会（IACAT）专业委员；澳门城市大学应用心理学博士讲师；澳门社会行为科学研究学会心理研究顾问；台湾静宜大学艺术治疗助理教授
李郁文 副教授，心理谘商辅导博士台湾静宜大学社工系兼任副教授台湾静宜大学谘商辅导中心主任 台中教区八家启智机构执行长
陈灿锐 副教授，广州医科大学心理学系副教授资深心理咨询师和督导师华人心理分析联合会理事心理咨询与治疗委员会委员
陈小飞 副教授，广东工业大学副教授北京大学心理学与艺术双硕士广东省优秀民办校长

t3-认证单位：国际创意艺术治疗学会；惟一心理学院；中国心理卫生协会

t3-绘画心理分析与治疗简介：
绘画心理分析与治疗是公认的易学易用的心理学工具，能够帮助来访者跨越语言的鸿沟，放下心理防御，进行情感的表达。弗洛伊德曾经强调了压抑、 升华、 投射等机制对绘画过程的影响。和弗洛伊德相比， 荣格更关注集体潜意识的作用， 强调原型在绘画中的象征和作用。在临床心理咨询中，心理咨询师透过画画，从主题（Theme）、色彩（Color）、线条（Line）、构图（Composition）...等，透过语言（Verbal）与非语言（Non- verbal），来分析图画中隐藏的秘密。这些一笔一画呈现出的图像，可能隐含着个案的潜意识与被压抑的内心情绪，心理咨询师便能透过这些图像中的象征意涵，深入理解个案的自我想法与需求，可以在过程中帮助来访者认识自我、成为自我、甚至摆脱过去阴影，从而更加有效的进行心理辅导。

t3-初级绘画心理疗愈师认证名单：
计小琼；韩玉娟；徐荣华；亓亚君；许露薇；栾慧明；朱晓云；贾辉；欧阳中越；卢淑华；
郭廷锦；王露梅； 尹蕾；唐卓；李旖要；黄菁；陈建霞；黄碧睽；周玉兰；曹丽敏；黎奕敏；程语；宋明娜。
`, url: require("../../imgs/train/3.jpg") },
{ content: `t2-原型音乐治疗（AMP)初级认证课程

t3-授课导师： 乔尓·克罗克(Joel Kroeker)
IAAP/音乐治疗师；TED演讲学者；获得国际奖项的表演艺术家；荣格音乐治疗国际领军人物

t3-认证单位：国际创意艺术治疗学会；惟一心理学院

t3-原型音乐治疗简介： 
乔尔·克罗克（Joel Kroeker）将广泛的临床案例与分析理论相结合，通过音乐表达的视角深度研究了心理分析的过程，并提出了在分析中工作音乐符号的方法，他称之为原型音乐心理治疗(Archetypal Music Psychotherapy, AMP)。乔尔·克罗克通过将音乐提炼成基本的原型元素，他阐明了神秘的、音乐的心灵在我们生活中的指引作用，为深度心理学提供了新的的视角。他擅长在广泛的背景下领导个人和团体音乐治疗专业培训课程，经验十分丰富，包括成人心理健康、接受复杂护理的老年人、有风险的青少年和发育迟缓的儿童，包括唐氏综合征、脑瘫和自闭症谱系障碍。

t3-初级原型音乐疗愈师认证名单：
刘晶；王宇琳；桑田；吴扬慧；李彩花；刘纯成；徐琳；周永莉；戚晔；仇萌；储丽莎；陈颖；康国胜；刘倩；丁奕。
`, url: require("../../imgs/train/4.jpg") },
{ content: `t2-「八周正念认知生活」（MBCT-L）认证班

t3-授课导师： 林隆璇 副教授
英国牛津大学正念认知疗法教师（MBCT-Ｄ）• 英国牛津大学正念认知生活教师（MBCT-L）• 北京清华大学正向心理学培训班• 正念表演力创始者•表达性艺术-「儿童正念与游戏Mindfulness and Play for Children 」培训师

t3-认证单位：国际创意艺术治疗学会；惟一心理学院

t2-MBCT-L简介：
正念认知（Mindfulness-based Cognitive Therapy，MBCT），最初是为了帮助康复期的抑郁症患者预防复发的心理干预方法，目前已经受到全球医学界和心理学界的工作者的关注和认可，并扩展到更广泛的情绪问题的心理调节。大量实证研究和临床实践表明，MBCT对失眠、焦虑、慢性疲劳、情绪障碍、癌症康复和社交恐惧等诸多身心问题方面有着显著效用。正念认知生活（MBCT for Life）是牛津大学正念中心开发的新教案，是在正念认知疗法（MBCT）的框架之上，发展出来的团体课程，旨在服务于更广泛的一般人群。这个课程和生活的融合更加密切，它指导参与者将正念应用于日常生活中，应对困难，处理痛苦，同时培育培育坚韧、平和、自在、幸福的品质。
`, url: require("../../imgs/train/5.jpg") },
{ content: `t2-哀伤辅导技术系统直播22讲

t3-授课导师： 
锁朋，心理咨询师；临床医师某国际紧急救援中心医疗、心理培训师中国医药卫生文化协会社区心理专委会副主任；中国医药教育协会心理与精神健康教育委员会委员
陈丽云教授，香港大学博士生导师，香港大学行为健康教研中心创立总监，国际死亡及哀伤工作专家组织成员

t3-认证单位：国际创意艺术治疗学会；惟一心理学院

t3-哀伤辅导技术简介：
哀伤辅导（Grief Counseling）是指专业人员协助人们在合理时间内，引发正常的悲伤，并健康地完成悲伤任务，以增进重新开始正常生活的能力对自己：表达哀思，减轻至亲/好友去世的悲痛，预防延长哀伤障碍，更好地适应新生活。对亲友：学习哀伤辅导技能，陪伴身边的亲人朋友度过悲伤时刻，走出失去亲人的痛苦。对来访者：在生命丧失相关的重大危机事件后，提供专业的哀伤个体辅导和团体辅导。心理咨询师、社会工作者；想要帮助亲友走过哀伤的人；经历过哀伤或对哀伤辅导感兴趣的人都可以学习。
`, url: require("../../imgs/train/6.jpg") },
{ content: `t2-禅与心理分析（艺术疗愈方向）直播训练营16讲

t3-授课导师：
吴宗宪 教授，参禅20余年；中国台湾知名音乐家；中国台湾文化大学音乐学系专任教授；中国台湾笛箫协会副理事长；中国台湾华冈艺术学校董事
王求是 副教授，参禅20余年，心理学博士，知足疗法创始人，暨南大学副教授，澳门城市大学心理分析专业博士生导师
林隆璇 副教授、著名音乐制作人；中国台湾台南应用科技大学副教授；牛津大学正念MBCT教师；前500强企业正念导师

t3-认证单位：国际创意艺术治疗学会；惟一心理学院

t3-禅与心理分析简介：
禅与心理分析（Chan and Psychoanalysis）各自代表了东方与西方的典型思想，但二者都是探索心灵奥秘的方法，意在消解烦恼，发现自性，让内心从束缚走向自由，发展出幸福和爱的能力。导师拥有20余年参禅经验、心理分析、音乐治疗学术背景。把禅学与音乐融入心理辅导技术中，体悟东方文化精髓及心身合一之道，学习禅者的心法、生活方式及思维方式。观摩临床案例的艺术疗愈及背后的原理感受艺术中的禅意：音乐审美、诗词审美等`, url: require("../../imgs/train/7.jpg") }]
function Train() {
    let isPC = mainStore.windowSize.width > mainStore.windowSize.height;
    return <div className={styles.root}>
        <div className={styles.container}>
            {
                contents.map((item, index) => {
                    return <div key={index} className={classnames(styles.subContainer, isPC ? null : styles.mobile)}>
                        {
                            Utils.getContentView(item.content, {
                                "t1": classnames(styles.t1, isPC ? styles.pc_right : styles.mobile),
                                "t2": classnames(styles.t2, isPC ? styles.pc_right : styles.mobile),
                                "t3": classnames(styles.t3, isPC ? styles.pc_right : styles.mobile),
                                "txt": classnames(styles.txt, isPC ? styles.pc_right : styles.mobile)
                            })
                        }
                        <div className={classnames(styles.img, isPC ? null : styles.mobile)} style={{ backgroundImage: "url(" + item.url + ")" }}></div>
                    </div>
                })
            }
        </div>
        <Bottom></Bottom>
    </div>
}


export default Train;